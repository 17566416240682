import "../css/style.css";
const Hero = () => {
  return (
    <section class="section-hero flex-ac-jc">
      <div class="hero flex--c-ac">
        <div class="hero__text-box">
          <h1 class="heading-primary">

            <div class="glitch-container grid">

              <span>JIC Capital</span>
              <span>JIC Capital</span>
              <span>JIC Capital</span>
            </div>
          </h1>
          <p class="paragraph paragraph_hero">
            JIC Capital focuses on searching and investing in the most cutting-edge, epoch-making technologies worldwide, attempting to redefine the world of intelligent value.
          </p>
          <p class="paragraph">

          </p>
        </div>

        <div class="hero__cta">
          <a href="#portfolio" class="btn btn--cta">Learn More</a>
        </div>
      </div>
    </section>
  );
}
export default Hero;