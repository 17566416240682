import logo from '../img/JIClogo.png';
const Footer = () => {
    return (
        <footer class="footer flex-ac" >
            <figure class="footer__logo-box" >
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a href="" style={{ display: 'flex', alignItems: 'center' }}
                ><img src={logo} alt="Police icon" style={{ width: 150 }} />
                    {/* <img src={slash} alt="Blue slash symbol" /> */}
                    {/* <strong class="logo__text">JIC Capital</strong> */}
                </a>

            </figure>

            <nav class="footer__navigation">
                <ul class="footer__menu flex">
                    <li><a href="">Home</a></li>
                    <li><a href="#about">Designed by JIC Capital</a></li>
                </ul>
            </nav>
        </footer>
    )
}
export default Footer;