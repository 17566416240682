import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { portfolioStages, portfolioSections } from './data';
import { useState } from 'react';
import aion from '../img/portfolio/AION.png';
import ardr from '../img/portfolio/ARDR.png';
import eos from '../img/portfolio/EOS.webp';
import neo from '../img/portfolio/NEO.jpg';
import qtum from '../img/portfolio/QTUM.png';
import fsn from '../img/portfolio/FSN.png';
import omg from '../img/portfolio/OMG.png';
import pay from '../img/portfolio/PAY.png';
import cic from '../img/portfolio/CIC.png';
import swftc from '../img/portfolio/SWFTC.jpg';
import chng from '../img/portfolio/CHNG.jpg';
import virgo from '../img/portfolio/VIRGO.png';
import sc from '../img/portfolio/SC.jpg';
import dmc from '../img/portfolio/DMC.jpg';
import red from '../img/portfolio/RED.png';
import itc from '../img/portfolio/ITC.png';
import ven from '../img/portfolio/VEN.png';
import rct from '../img/portfolio/RCT.png';
import int from '../img/portfolio/INT.png';
import ruff from '../img/portfolio/RUFF.jpg';
import bat from '../img/portfolio/BAT.png';
import pra from '../img/portfolio/PRA.png';
import mdt from '../img/portfolio/MDT.jpg';
import pst from '../img/portfolio/PST.png';
import ink from '../img/portfolio/INK.png';
import cvc from '../img/portfolio/CVC.webp';
import xvg from '../img/portfolio/XVG.png';
import tnb from '../img/portfolio/TNB.png';
import xmx from '../img/portfolio/XMX.png';
import cwv from '../img/portfolio/CWV.png';
import wax from '../img/portfolio/WAX.jpg';
import hockey_ai from '../img/portfolio/hocke_ai.jpg';
import face_ai from '../img/portfolio/face_ai_b.png';
import rifo_ai from "../img/portfolio/ai/rifo.png";
import golf_ai from "../img/portfolio/ai/golf.jpg";
import house_ai from "../img/portfolio/ai/house.jpg";
import soud_ai from "../img/portfolio/ai/soud.jpg";
import weed_ai from "../img/portfolio/ai/weed.png";
import yumy_ai from "../img/portfolio/ai/yumy.png";
import dama_ai from "../img/portfolio/ai/dama.png";
import aims_org from "../img/portfolio/org/aims.jpg";
import japan_org from '../img/portfolio/org/japan.png';
import pqc_dao from '../img/portfolio/org/pqc.png';
import chinese_dao from '../img/portfolio/org/chinese.png';
import face_dao from '../img/portfolio/org/face.png';
import love_dao from '../img/portfolio/org/love.png';
import sexy_dao from '../img/portfolio/org/sexy.png';




import { ALL_STAGE, SEED, VENTURE, EXIT, ALL_SECTION, AI, BASE, FINANCE, EXCHANGE, STORAGE, SOFTWARE, IOT, ADVERTISING, BIGDATA, COPYRIGHT, SECURITY, COMMUNITY, GAMING } from './data';

const Portfolio = () => {
    const [stage, setStage] = useState(0)
    const [section, setSection] = useState(AI)

    let sectionMoreItems = [];
    for (let i = ADVERTISING; i < portfolioSections.length; i++) {
        sectionMoreItems.push({
            key: portfolioSections[i].id,
            label: (<p className={section === i ? 'portfolio-tabs-text-slected' : "portfolio-tabs-text"} onClick={() => { setSection(i) }}>{portfolioSections[i].label}</p>),
        })
    }
    let allStageItems = [];
    for (let i = ALL_STAGE; i < portfolioStages.length; i++) {
        allStageItems.push({
            key: portfolioStages[i].id,
            label: (<p className={stage === i ? 'portfolio-tabs-text-slected' : "portfolio-tabs-text"} onClick={() => { setStage(i) }}>{portfolioStages[i].label}</p>),
        })
    }
    let allSectionItems = [];
    for (let i = ALL_SECTION; i < portfolioSections.length; i++) {
        allSectionItems.push({
            key: portfolioSections[i].id,
            label: (<p className={section === i ? 'portfolio-tabs-text-slected' : "portfolio-tabs-text"} onClick={() => { setSection(i) }}>{portfolioSections[i].label}</p>),
        })
    }
    return (
        <section id="portfolio" class="section section-portfolio">
            <header class="section__header">
                <h2 class="heading-secondary">
                    Portfolio
                    {/* <strong class="heading__shadow"
                    >Portfolio
                    </strong> */}
                </h2>
            </header>
            <div className="portfolio-content">
                <div className="portfolio-tabs-pc portfolio-tabs-text">
                    <div className="portfolio-tabs-stages">
                        {/* eslint-disable jsx-a11y/anchor-is-valid */}
                        <a onClick={() => { setStage(ALL_STAGE) }} className={stage === ALL_STAGE ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>
                            {portfolioStages[ALL_STAGE].label}
                        </a>
                        <a onClick={() => { setStage(SEED) }} className={stage === SEED ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>
                            {portfolioStages[SEED].label}
                        </a>
                        <a onClick={() => { setStage(VENTURE) }} className={stage === VENTURE ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>
                            {portfolioStages[VENTURE].label}
                        </a>
                        <a onClick={() => { setStage(EXIT) }} className={stage === EXIT ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>
                            {portfolioStages[EXIT].label}
                        </a>
                    </div>
                    <div className="portfolio-tabs-sections portfolio-tabs-text">
                        <a onClick={() => { setSection(ALL_SECTION) }} className={section === ALL_SECTION ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>{portfolioSections[ALL_SECTION].label}</a>
                        <a onClick={() => { setSection(AI) }} className={section === AI ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>{portfolioSections[AI].label}</a>
                        <a onClick={() => { setSection(BASE) }} className={section === BASE ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>{portfolioSections[BASE].label}</a>
                        <a onClick={() => { setSection(COMMUNITY) }} className={section === COMMUNITY ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>{portfolioSections[COMMUNITY].label}</a>
                        <a onClick={() => { setSection(EXCHANGE) }} className={section === EXCHANGE ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>{portfolioSections[EXCHANGE].label}</a>
                        <a onClick={() => { setSection(STORAGE) }} className={section === STORAGE ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>{portfolioSections[STORAGE].label}</a>
                        <a onClick={() => { setSection(SOFTWARE) }} className={section === SOFTWARE ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>{portfolioSections[SOFTWARE].label}</a>
                        <a onClick={() => { setSection(IOT) }} className={section === IOT ? 'portfolio-tabs-text-slected' : 'portfolio-tabs-text'}>{portfolioSections[IOT].label}</a>

                        <Dropdown menu={{ items: sectionMoreItems }} placement='top'>
                            <a onClick={(e) => e.preventDefault()} className="portfolio-tabs-text">
                                More
                                <DownOutlined />
                            </a>
                        </Dropdown>
                    </div>
                </div>
                <div className='portfolio-tabs-mobile'>
                    <Dropdown menu={{ items: allStageItems }} placement='top'>
                        <a onClick={(e) => e.preventDefault()} className="portfolio-tabs-text">
                            Filter by stages
                            <DownOutlined />
                        </a>
                    </Dropdown>
                    <Dropdown menu={{ items: allSectionItems }} placement='top'>
                        <a onClick={(e) => e.preventDefault()} className="portfolio-tabs-text">
                            Filter by sections
                            <DownOutlined />
                        </a>
                    </Dropdown>
                </div>
                {(stage === ALL_STAGE || stage === EXIT) &&
                    <h2 style={{ alignSelf: 'flex-start', marginTop: 30 }}>Exits</h2>
                }
                <div className='portfolio-items'>

                    {/* AI */}
                    {((stage === ALL_STAGE || stage === SEED) && (section === ALL_SECTION || section === AI)) &&
                        <div className='portfolio-item'>
                            <img src={face_ai} className='portfolio-img' alt='FACE AI' />
                            <p className='portfolio-status'>FACE.AI</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === SEED) && (section === ALL_SECTION || section === AI)) &&
                        <div className='portfolio-item'>
                            <img src={hockey_ai} className='portfolio-img' alt='HOCKEY AI' />
                            <p className='portfolio-status'>HOCKEY.AI</p>
                        </div>
                    }


                    {((stage === ALL_STAGE || stage === SEED) && (section === ALL_SECTION || section === AI)) &&
                        <div className='portfolio-item'>
                            <img src={rifo_ai} className='portfolio-img-black' alt='RIFO AI' />
                            <p className='portfolio-status'>RIFO.AI</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === SEED) && (section === ALL_SECTION || section === AI)) &&
                        <div className='portfolio-item'>
                            <img src={golf_ai} className='portfolio-img-white' alt='GOLF3 AI' />
                            <p className='portfolio-status'>GOLF3.AI</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === SEED) && (section === ALL_SECTION || section === AI)) &&
                        <div className='portfolio-item'>
                            <img src={house_ai} className='portfolio-img-black' style={{ backgroundColor: '#161616' }} alt='HOUSE AI' />
                            <p className='portfolio-status'>HOUSE.AI</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === SEED) && (section === ALL_SECTION || section === AI)) &&
                        <div className='portfolio-item'>
                            <img src={soud_ai} className='portfolio-img-white' style={{ backgroundColor: '#ee1971' }} alt='SOUD AI' />
                            <p className='portfolio-status'>SOUD.AI</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === SEED) && (section === ALL_SECTION || section === AI)) &&
                        <div className='portfolio-item'>
                            <img src={weed_ai} className='portfolio-img-white' style={{ backgroundColor: '#20922f' }} alt='WEED AI' />
                            <p className='portfolio-status'>WEED.AI</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === SEED) && (section === ALL_SECTION || section === AI)) &&
                        <div className='portfolio-item'>
                            <img src={yumy_ai} className='portfolio-img-black' alt='YUMY AI' />
                            <p className='portfolio-status'>YUMY.AI</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === SEED) && (section === ALL_SECTION || section === AI)) &&
                        <div className='portfolio-item'>
                            <img src={dama_ai} className='portfolio-img-white' alt='DAMA AI' />
                            <p className='portfolio-status'>DAMA.AI</p>
                        </div>
                    }

                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === BASE)) &&
                        <div className='portfolio-item'>
                            <img src={aion} className='portfolio-img' alt='AION' />
                            <p className='portfolio-status'>AION</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === BASE)) &&
                        <div className='portfolio-item'>
                            <img src={eos} className='portfolio-img' alt='EOS' />
                            <p className='portfolio-status'>EOS</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === BASE)) &&
                        <div className='portfolio-item'>
                            <img src={ardr} className='portfolio-img' alt='ARDR' />
                            <p className='portfolio-status'>ARDR</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === BASE)) &&
                        <div className='portfolio-item'>
                            <img src={neo} className='portfolio-img' alt='NEO' />
                            <p className='portfolio-status'>NEO</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === BASE)) &&
                        <div className='portfolio-item'>
                            <img src={qtum} className='portfolio-img' alt='QTUM' />
                            <p className='portfolio-status'>QTUM</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === FINANCE)) &&
                        <div className='portfolio-item'>
                            <img src={fsn} className='portfolio-img' alt='FSN' />
                            <p className='portfolio-status'>FSN</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === FINANCE)) &&
                        <div className='portfolio-item'>
                            <img src={omg} className='portfolio-img' alt='OMG' />
                            <p className='portfolio-status'>OMG</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === FINANCE)) &&
                        <div className='portfolio-item'>
                            <img src={pay} className='portfolio-img' alt='PAY' />
                            <p className='portfolio-status'>PAY</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === FINANCE)) &&
                        <div className='portfolio-item'>
                            <img src={cic} className='portfolio-img' alt='CIC' />
                            <p className='portfolio-status'>CIC</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === EXCHANGE)) &&
                        <div className='portfolio-item'>
                            <img src={swftc} className='portfolio-img' alt='SWFTC' />
                            <p className='portfolio-status'>SWFTC</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === EXCHANGE)) &&
                        <div className='portfolio-item'>
                            <img src={chng} className='portfolio-img' alt='CHNG' />
                            <p className='portfolio-status'>CHNG</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === EXCHANGE)) &&
                        <div className='portfolio-item'>
                            <img src={virgo} className='portfolio-img' alt='VIRGO' />
                            <p className='portfolio-status'>VIRGO</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === STORAGE)) &&
                        <div className='portfolio-item'>
                            <img src={sc} className='portfolio-img' alt='STORAGE' />
                            <p className='portfolio-status'>STORAGE</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === STORAGE)) &&
                        <div className='portfolio-item'>
                            <img src={dmc} className='portfolio-img' alt='DMC' />
                            <p className='portfolio-status'>DMC</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === SOFTWARE)) &&
                        <div className='portfolio-item'>
                            <img src={red} className='portfolio-img' alt='RED' />
                            <p className='portfolio-status'>RED</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === IOT)) &&
                        <div className='portfolio-item'>
                            <img src={itc} className='portfolio-img' alt='ITC' />
                            <p className='portfolio-status'>ITC</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === IOT)) &&
                        <div className='portfolio-item'>
                            <img src={ven} className='portfolio-img' alt='VEN' />
                            <p className='portfolio-status'>VEN</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === IOT)) &&
                        <div className='portfolio-item'>
                            <img src={rct} className='portfolio-img' alt='RCT' />
                            <p className='portfolio-status'>RCT</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === IOT)) &&
                        <div className='portfolio-item'>
                            <img src={int} className='portfolio-img' alt='INT' />
                            <p className='portfolio-status'>INT</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === IOT)) &&
                        <div className='portfolio-item'>
                            <img src={ruff} className='portfolio-img' alt='RUFF' />
                            <p className='portfolio-status'>RUFF</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === ADVERTISING)) &&
                        <div className='portfolio-item'>
                            <img src={bat} className='portfolio-img' alt='BAT' />
                            <p className='portfolio-status'>BAT</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === ADVERTISING)) &&
                        <div className='portfolio-item'>
                            <img src={pra} className='portfolio-img' alt='PRA' />
                            <p className='portfolio-status'>PRA</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === BIGDATA)) &&
                        <div className='portfolio-item'>
                            <img src={mdt} className='portfolio-img' alt='MDT' />
                            <p className='portfolio-status'>MDT</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COPYRIGHT)) &&
                        <div className='portfolio-item'>
                            <img src={pst} className='portfolio-img' alt='PST' />
                            <p className='portfolio-status'>PST</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COPYRIGHT)) &&
                        <div className='portfolio-item'>
                            <img src={ink} className='portfolio-img' alt='INK' />
                            <p className='portfolio-status'>INK</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === SECURITY)) &&
                        <div className='portfolio-item'>
                            <img src={cvc} className='portfolio-img' alt='CVC' />
                            <p className='portfolio-status'>CVC</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === SECURITY)) &&
                        <div className='portfolio-item'>
                            <img src={xvg} className='portfolio-img' alt='XVG' />
                            <p className='portfolio-status'>XVG</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COMMUNITY)) &&
                        <div className='portfolio-item'>
                            <img src={tnb} className='portfolio-img-white' style={{ backgroundColor: '#ffc04e' }} alt='TNB' />
                            <p className='portfolio-status'>TNB</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COMMUNITY)) &&
                        <div className='portfolio-item'>
                            <img src={xmx} className='portfolio-img' alt='XMX' />
                            <p className='portfolio-status'>XMX</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COMMUNITY)) &&
                        <div className='portfolio-item'>
                            <img src={cwv} className='portfolio-img' alt='CWV' />
                            <p className='portfolio-status'>CWV</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COMMUNITY)) &&
                        <div className='portfolio-item'>
                            <img src={japan_org} className='portfolio-img-white' alt='JAPAN AI' />
                            <p className='portfolio-status'>JAPANESE.ORG</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COMMUNITY)) &&
                        <div className='portfolio-item'>
                            <img src={aims_org} className='portfolio-img-white' style={{ backgroundColor: '#00141b' }} alt='JAPAN AI' />
                            <p className='portfolio-status'>AIMS.CLUB</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COMMUNITY)) &&
                        <div className='portfolio-item'>
                            <img src={chinese_dao} className='portfolio-img-black'  alt='CHINESE DAO' />
                            <p className='portfolio-status'>CHINESE.ORG</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COMMUNITY)) &&
                        <div className='portfolio-item'>
                            <img src={pqc_dao} className='portfolio-img-white' style={{backgroundColor:'#ff8a3b'}} alt='qpc dao' />
                            <p className='portfolio-status'>PQC.DAO</p>
                        </div>
                    }
                    
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COMMUNITY)) &&
                        <div className='portfolio-item'>
                            <img src={love_dao} className='portfolio-img-white' alt='love dao' />
                            <p className='portfolio-status'>LOVE.DAO</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COMMUNITY)) &&
                        <div className='portfolio-item'>
                            <img src={face_dao} className='portfolio-img-white'  style={{backgroundColor:'#b1f416'}} alt='face dao' />
                            <p className='portfolio-status'>FACE.DAO</p>
                        </div>
                    }
                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === COMMUNITY)) &&
                        <div className='portfolio-item'>
                            <img src={sexy_dao} className='portfolio-img-black' alt='sexy dao' />
                            <p className='portfolio-status'>SEXY.DAO</p>
                        </div>
                    }


                    {((stage === ALL_STAGE || stage === EXIT) && (section === ALL_SECTION || section === GAMING)) &&
                        <div className='portfolio-item'>
                            <img src={wax} className='portfolio-img' alt='WAX' />
                            <p className='portfolio-status'>WAX</p>
                        </div>
                    }


                    <div className='portfolio-item'></div>
                    <div className='portfolio-item'></div>
                    <div className='portfolio-item'></div>

                </div>
            </div>
        </section>
    );
}
export default Portfolio;