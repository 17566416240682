import './App.css';
import About from './components/about';
import Contacts from './components/contact';
import Footer from './components/footer';
import Header from './components/header';
import Hero from './components/hero';
import Portfolio from './components/portfolio';
function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Hero/>
        <Portfolio/>
        <About/>
        <Contacts/>
        <Footer/>
      </main>
    </div>
  );
}

export default App;
