import "../css/style.css";
import gallery1 from "../img/gallery/gallery-1.webp";
import gallery2 from "../img/gallery/gallery-2.webp";
import gallery3 from "../img/gallery/gallery-3.jpg";
import gallery4 from "../img/gallery/gallery-4.webp";
import gallery5 from "../img/gallery/gallery-5.webp";
import gallery6 from "../img/gallery/gallery-6.webp";
import gallery7 from "../img/gallery/gallery-7.webp";
import gallery8 from "../img/gallery/nft.jpg";
import gallery9 from "../img/gallery/ufo.jpg";
import gallery10 from "../img/gallery/gallery-10.webp";
import gallery11 from "../img/gallery/gallery-11.webp";
const About = () => {
    return (
        <section id="about" class="section section-gallery">
            <header class="section__header">
                <h2 class="heading-secondary">
                    Who We Are
                    {/* <strong class="heading__shadow"
                    >What is JIC Capital?
                    </strong> */}
                </h2>
                <p class="paragraph">
                    JIC Capital focuses on searching and investing in the most cutting-edge, epoch-making technologies worldwide, attempting to redefine the world of intelligent value.
                </p>
                <p class="paragraph">
                    The main business includes equity investment, digital currency issuance, hedging and arbitrage, and currency value management.
                </p>

            </header>

            <div class="section__content">
                <div class="gallery">
                    <div class="grid">
                        <figure>
                            <img src={gallery1} alt="" />
                        </figure>
                        <figure>
                            <img src={gallery2} alt="People dancing" />
                        </figure>
                        <figure>
                            <img
                                src={gallery3}
                                alt="A guy standing and behind him there is a bright background"
                            />
                        </figure>
                        <figure>
                            <img
                                src={gallery4}
                                alt="A girl standing beside neon lights on the wall"
                            />
                        </figure>
                        <figure>
                            <img src={gallery5} alt="Bright lights" />
                        </figure>
                        <figure>
                            <img
                                src={gallery6}
                                alt="People dancing somewhere dark"
                            />
                        </figure>
                        <figure>
                            <img src={gallery7} alt="Purple corridor" />
                        </figure>
                        <figure>
                            <img
                                src={gallery8}
                                alt="A guy with a shiny object"
                            />
                        </figure>
                        <figure>
                            <img src={gallery9} alt="UFO" />
                        </figure>
                        <figure>
                            <img src={gallery10} alt="Staircase" />
                        </figure>
                        <figure>
                            <img src={gallery11} alt="City at night" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default About;