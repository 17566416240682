import "../css/style.css";
import logo from '../img/JIClogo.png';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { focusAreas } from "./data.js";
import { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { motion } from "framer-motion";

const Header = () => {
    const [openNav, setOpenNav] = useState(false);
    const variants = {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: '-100%' }
    }
    // const variantsRotate = {
    //     open: { rotate: 90 },
    //     closed: { rotate: 270 }
    // }
    return (
        <>
            <header class="header flex-ac header__pc">
                <figure class="header__logo-box">
                    {/* eslint-disable jsx-a11y/anchor-is-valid */}
                    <a href="" style={{ display: "flex", alignItems: 'center' }}
                    ><img src={logo} alt="JIC Logo" style={{ width: 300 }} />
                        {/* <strong class="logo__text">JIC Capital</strong> */}
                    </a>
                </figure>

                <nav class="header__navigation">
                    <ul class="header__menu flex">
                        <li><a href="#portfolio">portfolio</a></li>
                        <li><Dropdown menu={{ items: focusAreas }}>
                            <a onClick={(e) => e.preventDefault()} className="header__menu_dropdown">
                                focus area
                                <DownOutlined />
                            </a>
                        </Dropdown>
                        </li>
                        <li><a href="#about">about</a></li>
                        <li><a href="#contacts">Contact</a></li>
                    </ul>
                </nav>
            </header>
            <header class="header__mobile">

                <div className="header_mobile_top">
                    <figure class="header__logo-box">
                        <a href="" style={{ display: "flex", alignItems: 'center' }}
                        ><img src={logo} alt="JIC Logo" style={{ width: 200 }} />
                            {/* <strong class="logo__text">JIC  Capital</strong> */}
                        </a>
                    </figure>
                    {openNav ? (
                        <AiOutlineClose size={'1.75rem'} className='header_moible_switch' onClick={() => { setOpenNav(false) }} />
                    ) : (
                        <motion.div animate={{ rotate: 180 }}>
                            <AiOutlineMenu size={'1.75rem'} className='header_moible_switch' onClick={() => { setOpenNav(true) }} />
                        </motion.div>
                    )}
                    {/* {openNav ? (
                            <AiOutlineClose size={'1.75rem'} className='header_moible_switch' onClick={() => { setOpenNav(false) }} />
                        ) : (
                            <AiOutlineMenu size={'1.75rem'} className='header_moible_switch' onClick={() => { setOpenNav(true) }} /> 
                        )} */}
                </div>
                <motion.nav animate={openNav ? "open" : "closed"} variants={variants}>

                    <ul id="header_mobile_nav" className={openNav ? "header_mobile_nav_show" : "header_mobile_nav_hidden"}>
                        <li className="header_mobile_nav_item"><a href="#todos" className="header_mobile_nav_item_link">portfolio</a></li>
                        <li className="header_mobile_nav_dropdown"><Dropdown menu={{ items: focusAreas }}>
                            <a onClick={(e) => e.preventDefault()} style={{ borderBottom: 1, borderBlockColor: 'red' }} className="portfolio-tabs-text">
                                focus area
                                <DownOutlined />
                            </a>
                        </Dropdown>
                        </li>
                        <li className="header_mobile_nav_item"><a href="#about" className="header_mobile_nav_item_link">about</a></li>
                        <li className="header_mobile_nav_item"><a href="#contacts" className="header_mobile_nav_item_link">Contacts</a></li>

                    </ul>
                </motion.nav>

                {/* <nav class="header__navigation">
                    <ul class="header__menu flex">
                        <li><a href="#todos">portfolio</a></li>
                        <li><Dropdown menu={{ items: focusAreas }}>
                            <a onClick={(e) => e.preventDefault()} className="header__menu_dropdown">
                                focus area
                                <DownOutlined />
                            </a>
                        </Dropdown>
                        </li>
                        <li><a href="#about">about</a></li>
                        <li><a href="#contacts">Contacts</a></li>
                    </ul>
                </nav> */}
            </header>
        </>
    );
}
export default Header;