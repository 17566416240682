import cityBuilding from '../img/contacts.webp'
import slash from '../img/slash.svg';
import link_icon from '../img/link-icon-s.png';

const Contacts = () => {
  return (
    <section id="contacts" class="section section-contacts flex">
      <div class="contacts__text-box">
        <header class="section__header flex--c">
          <h2 class="heading-secondary">
            contact
            {/* <strong class="heading__shadow">contacts</strong> */}
          </h2>

          <div class="info flex--c">
            <div class="address">
              <span class="info__title">Address</span>
              <span><img src={slash} alt="Blue slash icon" /></span>
              <span class="info__information"
              >Suite 332, 3219 Yonge St, Toronto,ON,M4N 2L3
              </span>
            </div>
            <div class="phone">
              <span class="info__title">EMAIL</span>
              <span><img src={slash} alt="Blue slash icon" /></span>
              <span class="info__information">info@jic.io</span>
            </div>
            <div class="venue">
              <span class="info__title">X</span>
              <span><img src={slash} alt="Blue slash icon" /></span>
              <a href="https://twitter.com/JICcapital" target='_blank' rel='noreferrer' className='twitter__link'>
                <span class="info__information">@jiccapital</span>
                <img src={link_icon} alt='link icon' className='twitter__link__icon' />
              </a>

            </div>
          </div>
        </header>
      </div>

      <div class="contacts__image-box flex">
        <figure>
          <img src={cityBuilding} alt="City and buildings at night" />
        </figure>
      </div>
    </section>
  )
}
export default Contacts;