export const focusAreas = [
    {
        key: '1',
        label: (<p className="header__menu_dropdown_text">Decentralized AI</p>),
    },
    {
        key: '2',
        label: (<p className="header__menu_dropdown_text">Decentralized Stable Coins</p>),
    },
    {
        key: '3',
        label: (<p className="header__menu_dropdown_text">Decentralized Storage</p>),
    },
    {
        key: '4',
        label: (<p className="header__menu_dropdown_text">Extremely Scarce Data/Value</p>),
    },
    {
        key: '5',
        label: (<p className="header__menu_dropdown_text">VR/AR/Metaverse</p>),
    },
    {
        key: '6',
        label: (<p className="header__menu_dropdown_text">Post-Quantum Crypto</p>),
    },
    {
        key: '7',
        label: (<p className="header__menu_dropdown_text">Digital Eternality</p>),
    },
    {
        key: '8',
        label: (<p className="header__menu_dropdown_text">Future Community/DAO</p>),
    },
    {
        key: '9',
        label: (<p className="header__menu_dropdown_text">Real value entrance</p>),
    },
    {
        key: '10',
        label: (<p className="header__menu_dropdown_text">Unbounded finance/Defi</p>),
    },
    {
        key: '11',
        label: (<p className="header__menu_dropdown_text">Defi/Gamefi/NFT</p>),
    },
    {
        key: '12',
        label: (<p className="header__menu_dropdown_text">Poof Of Influential Power</p>),
    },
    {
        key: '13',
        label: (<p className="header__menu_dropdown_text">Subversive Education</p>),
    },
    {
        key: '14',
        label: (<p className="header__menu_dropdown_text">Eternal Youth</p>),
    },
    {
        key: '15',
        label: (<p className="header__menu_dropdown_text">Free Energy</p>),
    },
    {
        key: '16',
        label: (<p className="header__menu_dropdown_text">Anti-gravity/UFO</p>),
    },
];

export const ALL_STAGE = 0;
export const SEED = 1;
export const VENTURE = 2;
export const EXIT = 3;

export const portfolioStages = [
    { id: ALL_STAGE, label: "All Stages" },
    { id: SEED, label: "Seed" },
    { id: VENTURE, label: "Venture & Growth" },
    { id: EXIT, label: "Exits" }
];

export const ALL_SECTION = 0;
export const AI= 1;
export const BASE = 2;
export const COMMUNITY = 3;
export const EXCHANGE = 4;
export const STORAGE = 5;
export const SOFTWARE = 6;
export const IOT = 7;
export const ADVERTISING = 8;
export const BIGDATA = 9;
export const COPYRIGHT = 10;
export const SECURITY = 11;
export const FINANCE = 12;
export const GAMING = 13;
export const portfolioSections = [
    { id: ALL_SECTION, label: "All Sections" },
    { id: AI, label: "AI" },
    { id: BASE, label: "Base Chain" },
    { id: COMMUNITY, label: "Community" },
    { id: EXCHANGE, label: "Exchange" },
    { id: STORAGE, label: "Storage" },
    { id: SOFTWARE, label: "Software" },
    { id: IOT, label: "IoT" },
    { id: ADVERTISING, label: "Advertising" },
    { id: BIGDATA, label: "BigData" },
    { id: COPYRIGHT, label: "Copyright" },
    { id: SECURITY, label: "Security" },
    { id: FINANCE, label: "Finance" },
    { id: GAMING, label: "Gaming" }
];

// export const portfolioSections = [
//     { key: 1, label: (<p className="portfolio-tabs-text">All Sections</p>) },
//     { key: 2, label: (<p className="portfolio-tabs-text">Base Chain</p>) },
//     { key: 3, label: (<p className="portfolio-tabs-text">Finance</p>) },
//     { key: 4, label: (<p className="portfolio-tabs-text">Exchange</p>) },
//     { key: 5, label: (<p className="portfolio-tabs-text">Storage</p>) },
//     { key: 6, label: (<p className="portfolio-tabs-text">Software</p>) },
//     { key: 7, label: (<p className="portfolio-tabs-text">IoT</p>) },
//     { key: 8, label: (<p className="portfolio-tabs-text">Advertising</p>) },
//     { key: 9, label: (<p className="portfolio-tabs-text">BigData</p>) },
//     { key: 10, label: (<p className="portfolio-tabs-text">Copyright</p>) },
//     { key: 11, label: (<p className="portfolio-tabs-text">Security</p>) },
//     { key: 12, label: (<p className="portfolio-tabs-text">Community</p>) },
//     { key: 13, label: (<p className="portfolio-tabs-text">Gaming</p>) },
// ];